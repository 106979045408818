import React, { useState } from 'react';
import { Loader } from '../../Shared';
import {
  formatDate,
  formatTime,
  getDay,
  convertDate,
  getDays,
  borderColor,
} from '../../../utils/utilities';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import { AvailabilitySetting } from '../../Popup';

const verticalBorder = { borderLeftWidth: 1, borderColor: '#CCCCCC' };

const DashboardAvailability = props => {
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState(null);

  let availabilities = (props.availabilities.toJS().availabilities && props.availabilities.toJS().availabilities) || [];
  let availabilityPagy = props.availabilities.toJS().availabilityPagy;

  const handlePageClick = data => {
    props.getAvailabilities(data.selected + 1);
  };

  const checkHasPrivateTraining = selected_trainings => {
    const { providedServices } = props;
    const filtered = providedServices.filter(item => {
      return (
        selected_trainings.includes(parseInt(item.id)) &&
        item.attributes.privacy_setting === 'private'
      );
    });
    return filtered.length >= 1;
  };

  const getSingleAvailability = id => {
    props.getAvailability(id).then(response => {
      let { data } = response;
      if (data.id) props.openUpdateDateSelection(data.id, data.attributes);
    });
  };

  return (
    <div
      className="overflow-scroll bg-white rounded-md mt-2 shadow-md"
      style={{ height: '270px', borderRadius: 10 }}
      onClick={() => setSelected(null)}>
      {props.loader ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <table className="w-full my-2 gfg">
          <tbody>
            {availabilities.length > 0 ? (
              availabilities.map((availability, index) => (
                <tr className="cursor-pointer border-b rt-tr-group" key={index}>
                  <td
                    className={`font-sf-regular text-center text-gray-700 text-xs ${borderColor(
                      availability.attributes.training_type
                    )} rounded-md border-l-2`}>
                    {moment(availability.attributes.repetition_from).isSame(
                      moment(availability.attributes.repetition_until)
                    )
                      ? convertDate(formatDate(availability.attributes.repetition_from))
                      : convertDate(formatDate(availability.attributes.repetition_from)) +
                      ' - ' +
                      convertDate(formatDate(availability.attributes.repetition_until))}
                  </td>

                  <td
                    className="font-sf-regular text-center text-primary-color text-xs py-1"
                    style={verticalBorder}>
                    {formatTime(availability.attributes.from_time)}
                    {' - '}
                    {formatTime(availability.attributes.to_time)}
                  </td>

                  <td
                    className="font-sf-regular text-center text-primary-color text-xs py-1"
                    style={{ minWidth: '100px', borderLeftWidth: 1, borderColor: '#CCCCCC' }}>
                    {availability.attributes.repetition_frequency === 'daily'
                      ? getDay(availability.attributes.repetition_from)
                      : getDays(availability.attributes.days).length === 7 ||
                        getDays(availability.attributes.days).length === 0
                        ? 'All Days'
                        : getDays(availability.attributes.days)}
                  </td>

                  <td
                    className="font-sf-regular text-center text-primary-color text-xs py-1 capitalize"
                    style={verticalBorder}>
                    {availability.attributes.training_type}
                  </td>

                  <td className="flex items-end justify-end font-sf-regular text-primary-color text-xs py-1">
                    <div className="flex items-center px-3">
                      {checkHasPrivateTraining(availability.attributes.selected_trainings) && (
                        <FontAwesomeIcon icon={faLock} size="xs" color="gray" className="mr-1" />
                      )}

                      <AvailabilitySetting
                        id={availability.id}
                        setSelected={() => {
                          setSelected(availability.id);
                        }}
                        selected={selected}>
                        <div className="content-center flex-col">
                          <button
                            onClick={e => {
                              setSelected(null);
                              e.stopPropagation();
                              getSingleAvailability(availability.id);
                            }}
                            className="flex items-center text-black hover:text-gray-500 focus:outline-none p-2">
                            Edit
                          </button>

                          <button
                            onClick={e => {
                              setSelected(availability.id);
                              e.stopPropagation();
                              setLoader(true);
                              props.deleteEvent(availability.id).then(() => setLoader(false));
                            }}
                            className="flex items-center text-black hover:text-gray-500 focus:outline-none p-2">
                            Delete
                          </button>
                        </div>
                      </AvailabilitySetting>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
                <tr>
                  <td colSpan={6}>
                    <div fluid="true" className="flex items-center justify-center p-4">
                      {props.loader ? (
                        <Loader />
                      ) : (
                        <h5 className="primary-text-regular">No availabilities ahead.</h5>
                      )}
                    </div>
                  </td>
                </tr>
            )}
          </tbody>
        </table>
      )}

      {availabilityPagy && availabilityPagy.pages > 1 && (
        <div className="mt-4 text-primary-color">
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            breakLabel={<span className="gap">...</span>}
            pageCount={availabilityPagy.pages}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            previousLinkClassName={'previous_page'}
            nextLinkClassName={'next_page'}
            disabledClassName={'disabled'}
            activeClassName={'active'}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardAvailability;
